import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Title from "./Title";
import CsvDownloadButton from "react-json-to-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DashboardTable from "./DashboardTable";
import { fetchRooms } from "../../apis/RoomAPI";
import { fetchLocations } from "../../apis/LocationAPI";
import AddRoom from "./AddRoom";

function Rooms() {
    const [rooms, setRooms] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchRooms();
                const locationData = await fetchLocations();
                setRooms(data);
                setLocations(locationData)
            } catch (error) {
                // Handle error, show error message, etc.
            }
        };
        fetchData();
    }, []);

    const columns = [
        "Room ID",
        "Name",
        "Location",
        "Capacity",
        "Hourly Cost"
    ];

    const columnMapping = {
        "Room ID": "room_id",
        "Name": "name",
        "Location": "lid",
        "Capacity": "cap",
        "Hourly Cost": "credits_hourly",
    };

    // Function to retrieve company name based on tid
    const getLocationName = (lid) => {
        const location = locations.find(location => location.lid === lid);
        return location ? location.name : "N/A";
    };

    const formatValues = {
        Location: getLocationName,
    };

    const handleRoomAdded = async () => {
        // After a location is added, refetch the list of locations
        try {
            const data = await fetchRooms();
            setRooms(data); // Update locations state with fetched data
        } catch (error) {
            console.error("Error fetching Rooms after addition:", error);
            // Handle error, show error message, etc.
        }
    };

    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
            }}
        >
            <React.Fragment>
                <Title>
                    Rooms
                    <CsvDownloadButton
                        data={rooms}
                        filename={"Room List.csv"}
                        style={{
                            background: "none",
                            border: "none",
                            padding: "0px 0px 0px 10px",
                            float: "right",
                        }}
                    >
                        <FileDownloadOutlinedIcon />
                    </CsvDownloadButton>
                    <AddRoom onRoomAdded={handleRoomAdded} locationsList={locations} />
                </Title>
                <DashboardTable data={rooms} columns={columns} columnMapping={columnMapping} refreshData={fetchRooms} formatValues={formatValues} />
            </React.Fragment>
        </Paper>
    );
}

export default Rooms;
