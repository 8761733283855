import axios from "axios";

const getUsersApi = process.env.REACT_APP_API_URL + "/api/get-users-list";
const getTypesApi = process.env.REACT_APP_API_URL + "/api/get-user-type";
const editUserApi = process.env.REACT_APP_API_URL + "/api/edit-user";
const getUserApi = process.env.REACT_APP_API_URL + "/api/get-user?uid=";

const fetchUsers = async () => {
    try {
        const response = await axios.get(getUsersApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Users:", error);
        throw error;
    }
};

const fetchTypes = async () => {
    try {
        const response = await axios.get(getTypesApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Types:", error);
        throw error;
    }
};

const editUser = async (companyData) => {
    try {
        const response = await axios.post(editUserApi, companyData);
        return response.data;
    } catch (error) {
        console.error("Error editing User:", error);
        throw error;
    }
};

const fetchUser = async (uid) => {
    try {
        const response = await axios.get(getUserApi.concat(uid));
        return response.data;
    } catch (error) {
        console.error("Error fetching User:", error);
        throw error;
    }
};

export { fetchUsers, fetchTypes, editUser, fetchUser };
