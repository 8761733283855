import * as React from "react";
import { alpha } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Hero() {
  // This hook returns true if the screen width is less than 600px
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box id='hero'>
      <Container sx={{ pb: 8 }}>
        <Stack
          spacing={2}
          useFlexGap
          sx={{ width: { xs: "100%", sm: "100%" } }}
        >
          <Typography
            component='h1'
            variant='h1'
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Millennial 
            <Typography
              component='span'
              variant='h1'
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              Pod
            </Typography>
          </Typography>
          <Typography
            variant='h5'
            textAlign='center'
            color='text.secondary'
          >
            Millennial Pod is an innovative fully serviced co-working space
            <br />
            where companies & people grow together.
          </Typography>
          <Stack
            direction={"row"}
            textAlign='center'
            alignSelf='center'
            spacing={1.5}
            useFlexGap
            sx={{ pt: 2, width: { xs: "auto", sm: "auto" } }}
            marginBottom={5}
          >
            <Button
              href='/plans'
              variant='contained'
              color='primary'
              sx={{ padding: 3 }}
            >
              Plans
            </Button>
            <Button
              href='/locations'
              variant='contained'
              color='primary'
              sx={{ padding: 3 }}
            >
              Locations
            </Button>
          </Stack>
        </Stack>
        {/* Grid View */}
        {/* Only render the div if isMobile is false */}
        {!isMobile && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "40px",
              marginTop: "20px",
            }}
          >
            <img
              src='/static/images/image-list/lounge-view.jpeg'
              alt='Millennial Pod'
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: `0 0 16px 8px ${alpha("#CCE00F", 0.2)}`,
              }}
            />
            <img
              src='/static/images/image-list/open-seats.jpeg'
              alt='Millennial Pod'
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: `0 0 16px 8px ${alpha("#CCE00F", 0.2)}`,
              }}
            />
            <img
              src='/static/images/image-list/outside-view.jpeg'
              alt='Millennial Pod'
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: `0 0 16px 8px ${alpha("#CCE00F", 0.2)}`,
              }}
            />
          </div>
        )}
      </Container>
    </Box>
  );
}
