import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Alert,
  Divider,
  CircularProgress,
  Grid,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Fade,
} from "@mui/material";
import AppAppBar from "../components/AppAppBar";
import Footer from "../components/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "./getLPTheme";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axios from "axios";
import { fetchUser } from "../apis/UserAPI";
import PasswordStrengthBar from "react-password-strength-bar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export default function Profile() {
  const [mode, setMode] = useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [eventActionLoading, setEventActionLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const initialFormValues = useRef({});
  const authUser = useAuthUser();

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const resetPasswordFields = () => {
    setPassword("");
    setConfirmPassword("");
    setIsChangingPassword(false);
  };

  const isPasswordMatch = () => {
    if (!isChangingPassword) return true;
    return !password || !confirmPassword || password === confirmPassword;
  };

  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleFormChange = (event) => {
    const currentValues = {
      name: event.target.form.name.value,
      email: event.target.form.email.value,
    };

    setHasChanges(
      currentValues.name !== initialFormValues.current.name ||
        currentValues.email !== initialFormValues.current.email ||
        (isChangingPassword && password)
    );
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    handleFormChange(event);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    handleFormChange(event);
  };

  const handleInputChange = (event) => {
    handleFormChange(event);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClick = (eventId, companyId) => {
    setSelectedEventId(eventId);
    setSelectedCompanyId(companyId);
    setDeleteDialogOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    setLoading(true);
    setError("");
    setSuccess("");
    setShowAlert(false);

    try {
      const requestData = {
        uid: authUser.uid,
        name: formData.get("name"),
        contact: formData.get("email"),
        type_id: userData.user_type,
        tid: userData.tid,
        change_pass: isChangingPassword ? "True" : "False",
      };

      if (isChangingPassword) {
        if (password !== confirmPassword) {
          setError("Passwords do not match");
          setShowAlert(true);
          setLoading(false);
          return;
        }
        requestData.password = password;
      }

      await axios.post(
        process.env.REACT_APP_API_URL + "/api/edit-user",
        requestData
      );

      setSuccess("Profile updated successfully!");
      setShowAlert(true);
      setEditing(false);
      resetPasswordFields();
      setHasChanges(false);

      const updatedData = await fetchUser(authUser.uid);
      setUserData(updatedData[0]);
      initialFormValues.current = {
        name: updatedData[0].name,
        email: updatedData[0].contact,
      };
    } catch (error) {
      setError("Failed to update profile");
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setEventActionLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/delete-event`, {
        _id: selectedEventId,
        companyId: selectedCompanyId,
      });

      setEvents(events.filter((event) => event._id !== selectedEventId));
      setSuccess("Event deleted successfully!");
      setShowAlert(true);
    } catch (error) {
      setError("Failed to delete event");
      setShowAlert(true);
    } finally {
      setEventActionLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUser(authUser.uid);
        setUserData(data[0]);
        initialFormValues.current = {
          name: data[0].name,
          email: data[0].contact,
        };
      } catch (error) {
        setError("Failed to load user data");
        setShowAlert(true);
      } finally {
        setInitialLoading(false);
      }
    };

    loadUserData();
  }, [authUser.uid]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/get-events-list?myEvents=true`
        );
        const sortedEvents = response.data.sort(
          (a, b) => new Date(b.start) - new Date(a.start)
        );
        setEvents(sortedEvents);
      } catch (error) {
        setError("Failed to load events");
        setShowAlert(true);
      } finally {
        setEventsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (initialLoading) {
    return (
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <AppAppBar
          mode={mode}
          toggleColorMode={toggleColorMode}
        />
        <Box
          sx={{
            backgroundImage:
              "linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)",
            backgroundSize: "100% 200px",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
          <Typography
            variant='h6'
            sx={{ mt: 2 }}
          >
            Loading profile...
          </Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar
        mode={mode}
        toggleColorMode={toggleColorMode}
      />
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)",
          backgroundSize: "100% 200px",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          px: 3,
        }}
      >
        <Container maxWidth='xl'>
          <Grid
            container
            spacing={4}
          >
            {/* Profile Section */}
            <Grid
              item
              xs={12}
              md={4}
              lg={3}
            >
              <Paper
                sx={{
                  p: 3,
                  height: "fit-content",
                  position: "sticky",
                  top: { xs: 88, sm: 104 }, // Account for AppBar height + some padding
                  maxHeight: {
                    xs: `calc(100vh - 104px)`, // Mobile height calculation
                    sm: `calc(100vh - 120px)`, // Desktop height calculation
                  },
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: (theme) => theme.palette.divider,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      m: 1,
                      bgcolor: "primary.main",
                      width: 56,
                      height: 56,
                    }}
                  >
                    <AccountCircleIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Typography
                    component='h1'
                    variant='h5'
                    sx={{ mb: 3 }}
                  >
                    Profile
                  </Typography>

                  {userData && (
                    <Box
                      component='form'
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ width: "100%" }}
                    >
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='name'
                        label='Name'
                        name='name'
                        autoComplete='name'
                        defaultValue={userData.name}
                        disabled={!editing || loading}
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        defaultValue={userData.contact}
                        disabled={!editing || loading}
                        onChange={handleInputChange}
                      />

                      {editing && (
                        <>
                          <Divider sx={{ my: 3 }} />
                          <Typography
                            variant='h6'
                            sx={{ mb: 2 }}
                          >
                            Change Password
                          </Typography>

                          {!isChangingPassword ? (
                            <Button
                              fullWidth
                              variant='outlined'
                              onClick={() => setIsChangingPassword(true)}
                              disabled={loading}
                            >
                              Change Password
                            </Button>
                          ) : (
                            <>
                              <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label='New Password'
                                type='password'
                                id='password'
                                value={password}
                                onChange={handlePasswordChange}
                                disabled={loading}
                              />
                              <PasswordStrengthBar password={password} />
                              <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='confirmPassword'
                                label='Confirm New Password'
                                type='password'
                                id='confirmPassword'
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!isPasswordMatch()}
                                helperText={
                                  !isPasswordMatch() && "Passwords do not match"
                                }
                                disabled={loading}
                              />
                            </>
                          )}

                          <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
                            <Button
                              fullWidth
                              variant='outlined'
                              onClick={() => {
                                setEditing(false);
                                resetPasswordFields();
                                setHasChanges(false);
                              }}
                              disabled={loading}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='submit'
                              fullWidth
                              variant='contained'
                              disabled={
                                loading ||
                                !hasChanges ||
                                (isChangingPassword &&
                                  (!password ||
                                    !confirmPassword ||
                                    !isPasswordMatch()))
                              }
                            >
                              {loading ? (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <CircularProgress
                                    size={20}
                                    sx={{ mr: 1 }}
                                  />
                                  Saving...
                                </Box>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          </Box>
                        </>
                      )}

                      {!editing && (
                        <Button
                          fullWidth
                          variant='contained'
                          onClick={() => setEditing(true)}
                          sx={{ mt: 2 }}
                          disabled={loading}
                        >
                          Edit Profile
                        </Button>
                      )}
                    </Box>
                  )}

                  {(error || success) && (
                    <Box sx={{ mt: 2, width: "100%" }}>
                      {error && (
                        <Alert
                          variant='outlined'
                          severity='error'
                          sx={{ visibility: showAlert ? "visible" : "hidden" }}
                        >
                          {error}
                        </Alert>
                      )}

                      {success && (
                        <Alert
                          variant='outlined'
                          severity='success'
                          sx={{ visibility: showAlert ? "visible" : "hidden" }}
                        >
                          {success}
                        </Alert>
                      )}
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
            {/* Events Section */}
            <Grid
              item
              xs={12}
              md={8}
              lg={9}
            >
              <Paper sx={{ p: 3 }}>
                <Typography
                  variant='h5'
                  sx={{ mb: 3 }}
                >
                  My Bookings
                </Typography>

                {eventsLoading ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", my: 4 }}
                  >
                    <CircularProgress />
                  </Box>
                ) : events.length === 0 ? (
                  <Box sx={{ textAlign: "center", py: 4 }}>
                    <Typography color='text.secondary'>
                      No bookings found
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label='events table'
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Room</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Credits</TableCell>
                            <TableCell align='center'>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {events
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((event) => (
                              <TableRow
                                key={event._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  transition: "all 0.3s ease",
                                }}
                              >
                                <TableCell>{event.roomName}</TableCell>
                                <TableCell>{event.description}</TableCell>
                                <TableCell>
                                  {formatDateTime(event.start)}
                                </TableCell>
                                <TableCell>
                                  {formatDateTime(event.end)}
                                </TableCell>
                                <TableCell>{event.credits}</TableCell>
                                <TableCell align='center'>
                                  <Fade in={true}>
                                    <Tooltip title='Delete Booking'>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteClick(
                                            event._id,
                                            event.companyId
                                          )
                                        }
                                        disabled={eventActionLoading}
                                        color='error'
                                        aria-label='delete event'
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Fade>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component='div'
                      count={events.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteDialogOpen(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          {"Are you sure you want to delete this booking?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color='error'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
