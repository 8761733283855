import React, { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState, useEffect } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Autocomplete, Box, Grid } from "@mui/material";
import { EventFormData, ITodo } from "./EventCalendar";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Alert } from "@mui/material";
import BasicSelect from "../BasicSelect";
import axios from "axios";

interface IProps {
  open: boolean;
  handleClose: Dispatch<SetStateAction<void>>;
  eventFormData: EventFormData;
  setEventFormData: Dispatch<SetStateAction<EventFormData>>;
  onAddEvent: (e: MouseEvent<HTMLButtonElement>) => void;
  todos: ITodo[];
  timeSlotAvailable: boolean; // indicating if time slot is available
  start: string;
  end: string;
}

interface Company {
  tid: string;
  name: string;
  // Add other properties if present in the API response
}

const AddEventModal = ({
  open,
  handleClose,
  eventFormData,
  setEventFormData,
  onAddEvent,
  todos,
  timeSlotAvailable,
  start,
  end,
}: IProps) => {
  const authUser = useAuthUser<any>();
  const { description } = eventFormData;

  const onClose = () => handleClose();
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(
    authUser ? authUser.tid : null
  );
  const [loading, setLoading] = useState<boolean>(false); // State for loading

  // API endpoints
  const getCompaniesApi =
    process.env.REACT_APP_API_URL + "/api/get-companies-list";

  // Fetch rooms from the API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get<Company[]>(getCompaniesApi);
        setCompaniesList(response.data);
        //console.log('Companies API Response:', response.data);
      } catch (error) {
        console.error("Error fetching Companies:", error);
        // Handle error, show error message, etc.
      }
    };

    fetchCompanies();
  }, [getCompaniesApi]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEventFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTodoChange = (e: React.SyntheticEvent, value: ITodo | null) => {
    setEventFormData((prevState) => ({
      ...prevState,
      todoId: value?._id,
    }));
  };

  const onCompanyChange = (value: number) => {
    console.log("Value:", value); // Log the value to check if it's null or expected
    setEventFormData((prevState) => ({
      ...prevState,
      companyId: value, // Assuming companyId is a string, convert it to number
    }));
    setSelectedCompany(value); // Optionally, update the local state as well
  };

  const generateAlerts = () => {
    const alerts = [];

    if (authUser === null) {
      alerts.push({
        variant: "outlined" as "outlined",
        severity: "error" as "error",
        message: "You must sign in to an account to book. If you do not have an account, please call +918779657061 for assistance.",
      });
    }

    if (!timeSlotAvailable && authUser?.typeId < 3) {
      alerts.push({
        variant: "outlined" as "outlined",
        severity: "error" as "error",
        message: "Time slot unavailable.",
      });
    }

    // Add a special alert for authUser with typeId 4 and timeSlot not available
    if (authUser !== null && authUser?.typeId >= 3 && !timeSlotAvailable) {
      alerts.push({
        variant: "outlined" as "outlined",
        severity: "warning" as "warning", // You can change the severity accordingly
        message: "Time selected is in the past or overlaps another event.",
      });
    }

    return alerts.map((alert, index) => (
      <Alert
        key={index}
        variant={alert.variant}
        severity={alert.severity}
        sx={{ marginBottom: 1 }}
      >
        {alert.message}
      </Alert>
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>New Meeting ({start} - {end})</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To book a meeting room, please fill in the information below.
        </DialogContentText>
        <Box component='form'>
          {authUser?.typeId >= 3 && (
            <Box sx={{ mt: 3 }}>
              <BasicSelect
                label='Company'
                itemList={companiesList.map((company) => ({
                  value: company.tid,
                  label: company.name,
                }))}
                selectedValue={selectedCompany}
                onValueChange={onCompanyChange} // Ensure this passes both parameters
              />
            </Box>
          )}
          <TextField
            sx={{ mt: 3 }}
            name='description'
            value={description}
            margin='dense'
            id='description'
            label='Description'
            type='text'
            fullWidth
            variant='outlined'
            onChange={onChange}
          />
          {/* Hidden for now */}
          <Autocomplete
            onChange={handleTodoChange}
            disablePortal
            id='combo-box-demo'
            options={todos}
            sx={{ marginTop: 3, display: "none" }}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Todo'
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          direction='column'
          alignItems={"center"}
        >
          <Grid item>
            <Button
              onClick={onClose}
              variant='outlined'
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || (!timeSlotAvailable && (authUser === null || authUser.typeId < 3)) || description === ""}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                setLoading(true); // Set loading state to true
                onAddEvent(e); // Pass the MouseEvent parameter to onAddEvent function
                // Set loading back to false after 5 seconds
                setTimeout(() => {
                  setLoading(false);
                }, 5000);
              }}
              variant='contained'
            >
              {loading ? 'Booking...' : 'Book'}
            </Button>
          </Grid>
          <Grid item>{generateAlerts()}</Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventModal;
