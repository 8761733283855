import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import axios from "axios";
import { useState, useEffect } from "react";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";

export default function Locations() {
  // Google Maps API key
  const googleMapsAPIkey = "AIzaSyAOgYch9guZQgPt3G4JEWfRfBqxPGHG9x0";
  const mapID = "8e9592bab2e3b406"

  // State variables
  const [locationsList, setLocationsList] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  // API endpoint for fetching locations
  const getLocationsApi =
    process.env.REACT_APP_API_URL + "/api/get-locations";

  // Fetch locations from the API
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(getLocationsApi);
        setLocationsList(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
        // Handle error, show error message, etc.
      }
    };

    fetchLocations();
  }, [getLocationsApi]);

  // Determine the position for the selected feature
  const selectedFeature =
    locationsList.length > 0 ? locationsList[selectedItemIndex] : null;
  var position = selectedFeature
    ? { lat: selectedFeature.lat, lng: selectedFeature.lng }
    : { lat: 0, lng: 0 };

  // Handle item click to update selected item index
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  return (
    <Container
      id='features'
      sx={{ pb: { xs: 8, sm: 8 } }}
    >
      <Grid
        container
        spacing={6}
      >
        {/* Grid item for displaying locations */}
        <Grid
          item
          xs={12}
          md={6}
        >
          <div>
            {/* Title */}
            <Typography
              component='h2'
              variant='h2'
              color='text.primary'
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Locations
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {/* Render chips for each location */}
            {locationsList.map(({ name }, index) => (
              <Chip
                key={index}
                label={name}
                onClick={() => handleItemClick(index)}
                sx={{
                  background: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "none" : "";
                    }
                    return selectedItemIndex === index ? "none" : "";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#000000" : "",
                  },
                }}
              />
            ))}
          </Grid>
          {/* Display selected feature */}
          {selectedFeature && (
            <Box
              component={Card}
              variant='outlined'
              sx={{
                display: { xs: "auto", sm: "none" },
                mt: 4,
              }}
            >
              <Box
                sx={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  mb: 3,
                  minHeight: 280,
                }}
              >
                {/* Google Maps integration */}
                <div
                  style={{
                    height: "50vh",
                    width: "100%",
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <APIProvider apiKey={googleMapsAPIkey}>
                    <Map
                      defaultCenter={position}
                      defaultZoom={12}
                      mapId={mapID}
                    >
                      <AdvancedMarker position={position} />
                    </Map>
                  </APIProvider>
                </div>
              </Box>
              {/* Details of selected feature */}
              <Box sx={{ px: 2, pb: 2 }}>
                <Typography
                  color='text.primary'
                  variant='body2'
                  fontWeight='bold'
                >
                  {selectedFeature.name}
                </Typography>
                <Typography
                  color='text.secondary'
                  variant='body2'
                  sx={{ my: 0.5 }}
                >
                  {selectedFeature.address}
                </Typography>
                <Link
                  href='/plans'
                  color='primary'
                  variant='body2'
                  fontWeight='bold'
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    "& > svg": { transition: "0.2s" },
                    "&:hover > svg": { transform: "translateX(2px)" },
                  }}
                >
                  <span>Learn more</span>
                  <ChevronRightRoundedIcon
                    fontSize='small'
                    sx={{ mt: "1px", ml: "2px" }}
                  />
                </Link>
              </Box>
            </Box>
          )}
          {/* Stack for displaying locations on larger screens */}
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {locationsList.map(({ name, address }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  boxShadow: (theme) => {
                    return selectedItemIndex === index
                      ? "0px 0px 8px 4px rgba(203, 224, 15, 0.2)"
                      : "none";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  {/* Location details */}
                  <div>
                    <Typography
                      color='text.primary'
                      variant='body2'
                      fontWeight='bold'
                    >
                      {name}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      variant='body2'
                      sx={{ my: 0.5 }}
                    >
                      {address}
                    </Typography>
                    <Link
                      href='/plans'
                      color='primary'
                      variant='body2'
                      fontWeight='bold'
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize='small'
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        {/* Grid item for displaying map on larger screens */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          {/* Google Maps integration */}
          <div
            style={{
              height: "50vh",
              width: "100%",
              boxShadow: "0px 0px 8px 4px rgba(203, 224, 15, 0.2)",
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <APIProvider apiKey={googleMapsAPIkey}>
              <Map
                Center={position}
                defaultZoom={12}
                mapId={mapID}
              >
                <AdvancedMarker position={position} />
              </Map>
            </APIProvider>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
