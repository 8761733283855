import { SetStateAction, MouseEvent, Dispatch } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { IEventInfo } from "./EventCalendar";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Alert from "@mui/material/Alert";

interface IProps {
  open: boolean;
  handleClose: Dispatch<SetStateAction<void>>;
  onDeleteEvent: (e: MouseEvent<HTMLButtonElement>) => void;
  currentEvent: IEventInfo | null;
}

const EventInfoModal = ({
  open,
  handleClose,
  onDeleteEvent,
  currentEvent,
}: IProps) => {
  const onClose = () => {
    handleClose();
  };
  const authUser = useAuthUser<any>();
  var currentdate = new Date();
  var diff = 0;
  if (currentEvent?.start !== undefined) {
    diff = currentEvent.start.getTime() - currentdate.getTime();
  }

  var uid = -1;
  var utype = -1;
  var tid = -1;
  if (authUser !== null) {
    uid = authUser.uid;
    utype = authUser.typeId;
    tid = authUser.companyId;

    console.log(uid);
    console.log(currentEvent?.userId)
  }

  // Determine the error message based on the conditions
  let errorMessage = "";

  if (authUser === null) {
    errorMessage = "Please log in to delete the event.";
  } else if (diff < 0) {
    errorMessage =
      "This event is in the past. This action will refund the users credits.";
  } else if (uid !== currentEvent?.userId) {
    errorMessage =
      "This event is not created by you. This action will refund the users credits.";
  } else if (utype < 3) {
    errorMessage = "You need the appropriate user type to delete events.";
  }

  console.log(errorMessage);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Booking Info</DialogTitle>
      <DialogContent>
        <DialogContentText hidden={(uid !== currentEvent?.userId || tid === currentEvent?.companyId) && utype < 3}>
          <Typography
            sx={{ fontSize: 16, marginTop: 1, fontWeight: "bold" }}
            color='text.secondary'
            gutterBottom
          >
            {currentEvent?.description}
          </Typography>
          <Typography
            sx={{ fontSize: 14, marginTop: 1 }}
            color='text.secondary'
            gutterBottom
          >
            Booked By: {currentEvent?.email}
          </Typography>
          <Typography
            sx={{ fontSize: 14, marginTop: 1 }}
            color='text.secondary'
            gutterBottom
          >
            Company: {currentEvent?.companyName}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography
            sx={{ fontSize: 14, marginTop: 1 }}
            color='text.secondary'
            gutterBottom
          >
            Start: {currentEvent?.start?.toDateString()}{" "}
            {currentEvent?.start?.toLocaleTimeString()}
          </Typography>
          <Typography
            sx={{ fontSize: 14, marginTop: 1 }}
            color='text.secondary'
            gutterBottom
          >
            End: {currentEvent?.end?.toDateString()}{" "}
            {currentEvent?.end?.toLocaleTimeString()}
          </Typography>
        </DialogContentText>
        <Box component='form'></Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='outlined'
        >
          Cancel
        </Button>
        <Button
          onClick={onDeleteEvent}
          variant='contained'
          disabled={
            (authUser === null || diff < 0 || uid !== currentEvent?.userId) &&
            utype < 3
          }
          aria-describedby={errorMessage ? "delete-event-error" : undefined}
        >
          Delete Event
        </Button>
      </DialogActions>
      {utype >= 3 && errorMessage !== "" && (
        <Alert
          variant='outlined'
          severity='warning'
          sx={{ marginBottom: 1, margin: 1 }}
        >
          {errorMessage}
        </Alert>
      )}

    </Dialog>
  );
};

export default EventInfoModal;
