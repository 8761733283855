import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Title from "./Title";
import CsvDownloadButton from "react-json-to-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DashboardTable from "./DashboardTable";
import AddLocation from "./AddLocation";
import { fetchLocations } from "../../apis/LocationAPI";
import { fetchCompanies } from "../../apis/CompanyAPI";

function Locations() {
    const [locations, setLocations] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchLocations();
                const companiesData = await fetchCompanies();
                setLocations(data);
                setCompanies(companiesData);
            } catch (error) {
                // Handle error, show error message, etc.
            }
        };
        fetchData();
    }, []);

    //Format Managed Column to show Yes if Managed and No if Unmanaged
    const formatIsManaged = (value) => {
        return value === 1 ? "Managed" : "Unmanaged";
    };

    const columns = [
        "Location ID",
        "Name",
        "City",
        "Address",
        "Contact",
        "Capacity",
        "Latitude",
        "Longitude",
        "Managed",
        "Company",
    ];

    const columnMapping = {
        "Location ID": "lid",
        "Name": "name",
        "City": "city",
        "Address": "address",
        "Contact": "contact",
        "Capacity": "capacity",
        "Latitude": "lat",
        "Longitude": "lng",
        "Managed": "isManaged",
        "Company": "tid",
    };

    // Function to retrieve company name based on tid
    const getCompanyName = (tid) => {
        const company = companies.find(company => company.tid === tid);
        return company ? company.name : "N/A";
    };

    const handleLocationAdded = async () => {
        // After a location is added, refetch the list of locations
        try {
            const data = await fetchLocations();
            setLocations(data); // Update locations state with fetched data
        } catch (error) {
            console.error("Error fetching Locations after addition:", error);
            // Handle error, show error message, etc.
        }
    };

    const formatValues = {
        Managed: formatIsManaged,
        Company: getCompanyName
    };

    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
            }}
        >
            <React.Fragment>
                <Title>
                    Locations
                    <CsvDownloadButton
                        data={locations}
                        filename={"Location List.csv"}
                        style={{
                            background: "none",
                            border: "none",
                            padding: "0px 0px 0px 10px",
                            float: "right",
                        }}
                    >
                        <FileDownloadOutlinedIcon />
                    </CsvDownloadButton>
                    <AddLocation onLocationAdded={handleLocationAdded} companiesList={companies} />
                </Title>
                <DashboardTable data={locations} columns={columns} columnMapping={columnMapping} refreshData={fetchLocations} formatValues={formatValues} />
            </React.Fragment>
        </Paper>
    );
}

export default Locations;
