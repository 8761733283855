// RoomBookings.js

import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Title from "./Title";
import axios from "axios";
import CsvDownloadButton from "react-json-to-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DashboardTable from "./DashboardTable";

function RoomBookings() {
  const [bookings, setBookings] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [creditsByDate, setCreditsByDate] = useState([]);

  // api endpoint
  const getEventsApi =
    process.env.REACT_APP_API_URL + "/api/get-events-list?allEvents=true";

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(getEventsApi);
                // Format the date before setting it to state
                const formattedBookings = response.data.map(booking => ({
                  ...booking,
                  end: new Date(booking.end).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true }),
                  start: new Date(booking.start).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true }),
                  date: new Date(booking.start).toLocaleDateString()
                }));
                
        setBookings(formattedBookings);

        // Calculate total credits
        const creditsSum = response.data.reduce((sum, booking) => sum + booking.credits, 0);
        setTotalCredits(creditsSum);

        // Calculate min and max dates
        const bookingDates = response.data.map(booking => new Date(booking.start));
        setMinDate(new Date(Math.min(...bookingDates)));
        setMaxDate(new Date(Math.max(...bookingDates)));

        // Calculate credits by date
        const creditsByDateObj = response.data.reduce((acc, booking) => {
          const date = new Date(booking.start).toLocaleDateString();
          acc[date] = (acc[date] || 0) + booking.credits;
          return acc;
        }, {});

        const creditsByDateArray = Object.keys(creditsByDateObj).map(date => ({
          date,
          credits: creditsByDateObj[date],
        }));

        setCreditsByDate(creditsByDateArray);
      } catch (error) {
        console.error("Error fetching Rooms:", error);
        // Handle error, show error message, etc.
      }
    };

    fetchEvents();
  }, [getEventsApi]);

  const columns = [
    "Date",
    "Start Time",
    "End Time",
    "Room",
    "Company Name",
    "Description",
    "Email",
    "Credits"
  ];

  const columnMapping = {
    "Date": "date",
    "Start Time": "start",
    "End Time": "end",
    "Room": "roomName",
    "Company Name": "companyName",
    "Description": "description",
    "Email": "email",
    "Credits": "credits"
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 240 }}>
          <Chart creditsByDate={creditsByDate} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 240 }}>
          <Deposits totalCredits={totalCredits} minDate={minDate} maxDate={maxDate} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <React.Fragment>
            <Title>
              Room Bookings
              <CsvDownloadButton
                data={bookings}
                filename={"Booking List.csv"}
                style={{
                  background: "none",
                  border: "none",
                  padding: "0px 0px 0px 30px",
                  float: "right",
                }}
              >
                <FileDownloadOutlinedIcon />
              </CsvDownloadButton>
            </Title>
            <DashboardTable data={bookings} columns={columns} columnMapping={columnMapping} />
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default RoomBookings;
