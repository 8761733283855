import { Typography } from "@mui/material";
import { IEventInfo } from "./EventCalendar";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

interface IProps {
  event: IEventInfo;
}

const EventInfo = ({ event }: IProps) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated() ? (
        <>
          <Typography>{event.description}</Typography>
          <Typography>{event.email}</Typography>
        </>
      ) : (
        <>
          <Typography>Booked</Typography>
          <Typography></Typography>
        </>
      )}
    </>
  );
};

export default EventInfo;
