import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ShieldMoonRoundedIcon from "@mui/icons-material/ShieldMoonRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import DeskRoundedIcon from "@mui/icons-material/DeskRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const items = [
  {
    icon: <ShieldMoonRoundedIcon />,
    title: "Private Office",
    description:
      "Enclosed, lockable offices accommodating big & small teams. Fully furnished and serviced.",
    imageLight: 'url("/static/images/image-list/moscow.webp")',
    imageDark: 'url("/static/images/image-list/moscow.webp")',
  },
  {
    icon: <MeetingRoomRoundedIcon />,
    title: "Meeting Rooms",
    description:
      "In The Commercial Hub Of Mumbai. Fully Services And Equipped With All Facilities For Any Type Of Meetings.",
    imageLight: 'url("/static/images/image-list/vatican.webp")',
    imageDark: 'url("/static/images/image-list/moscow.webp")',
  },
  {
    icon: <DeskRoundedIcon />,
    title: "Hot Desk",
    description:
      "Guaranteed workspace in our common area. Just bring your laptop, pick an open seat, and get to work.",
    imageLight: 'url("/static/images/image-list/hot-desk.webp")',
    imageDark: 'url("static/images/image-list/hot-desk.webp")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    // console.log(index);
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container
      id='features'
      sx={{ pb: { xs: 8, sm: 8 } }}
    >
      <Grid
        container
        spacing={6}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <div>
            <Typography
              component='h2'
              variant='h4'
              color='text.primary'
            >
              Plans
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Just some of the plans we offer. View all in the plans page.
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  background: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "none" : "";
                    }
                    return selectedItemIndex === index ? "none" : "";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#000000" : "",
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant='outlined'
            sx={{
              display: { xs: "auto", sm: "none" },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: "cover",
                backgroundPosition: "center",
                mb: 3,
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography
                color='text.primary'
                variant='body2'
                fontWeight='bold'
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color='text.secondary'
                variant='body2'
                sx={{ my: 0.5 }}
              >
                {selectedFeature.description}
              </Typography>
              <Link
                href='/plans'
                color='primary'
                variant='body2'
                fontWeight='bold'
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  "& > svg": { transition: "0.2s" },
                  "&:hover > svg": { transform: "translateX(2px)" },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize='small'
                  sx={{ mt: "1px", ml: "2px" }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  boxShadow: (theme) => {
                    return selectedItemIndex === index
                      ? "0px 0px 8px 4px rgba(203, 224, 15, 0.2)"
                      : "none";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color='text.primary'
                      variant='body2'
                      fontWeight='bold'
                    >
                      {title}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      variant='body2'
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      href='/plans'
                      color='primary'
                      variant='body2'
                      fontWeight='bold'
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize='small'
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Box
            sx={{
              mt: 10,
              width: 520,
              height: 500,
              backgroundSize: "cover",
              borderRadius: "10px",
              boxShadow: "0px 0px 16px 8px rgba(203, 224, 15, 0.2)",
              backgroundRepeat: "no-repeat",
              backgroundImage: (theme) =>
                theme.palette.mode === "light"
                  ? items[selectedItemIndex].imageLight
                  : items[selectedItemIndex].imageDark,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
