import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { addLocation } from "../../apis/LocationAPI";

export default function AddLocation({ onLocationAdded, companiesList }) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [contact, setContact] = useState('');
    const [capacity, setCapacity] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [address, setAddress] = useState('');
    const [isManaged, setIsManaged] = useState('');
    const [tid, setTid] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            await addLocation({
                name,
                city,
                address,
                contact,
                capacity,
                lat,
                lng,
                isManaged,
                tid,
            });
            handleClose();
            onLocationAdded();
        } catch (error) {
            console.error('Error adding Location:', error);
        }
    };

    return (
        <React.Fragment>
            <AddRoundedIcon onClick={handleClickOpen} sx={{
                color: "black",
                background: "none",
                border: "none",
                float: "right",
                margin: "0px 0px 0px 10px",
            }} />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>New Location</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a location, please fill in these fields.
                    </DialogContentText>
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='name'
                        value={name}
                        margin='dense'
                        id='description'
                        label='Location Name'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='city'
                        value={city}
                        margin='dense'
                        id='city'
                        label='City'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='address'
                        value={address}
                        margin="dense"
                        id="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='contact'
                        value={contact}
                        margin="dense"
                        id="contact"
                        label="Contact"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setContact(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='capactity'
                        value={capacity}
                        margin='dense'
                        id='capacity'
                        label='Capacity'
                        type='number'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setCapacity(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='lat'
                        value={lat}
                        margin='dense'
                        id='lat'
                        label='Latitude'
                        type='number'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setLat(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='lng'
                        value={lng}
                        margin="dense"
                        id="lng"
                        label="Longitude"
                        type="number"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setLng(e.target.value)}
                    />
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="isManaged-label">Managed?</InputLabel>
                        <Select
                            required
                            value={isManaged}
                            onChange={(e) => setIsManaged(e.target.value)}
                            fullWidth
                            id="isManaged"
                            labelId="isManaged-label"
                            label="Managed?"
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="company-label">Company</InputLabel>
                        <Select
                            required
                            value={tid}
                            onChange={(e) => setTid(e.target.value)}
                            fullWidth
                            id="isManaged"
                            labelId="company-label"
                            label="Company"
                        >
                            {isManaged === 0 ? (
                                <MenuItem key="fixedCompany" value="1">
                                    {companiesList.find(company => company.tid === 1)?.name || 'Company Not Found'}
                                </MenuItem>
                            ) : (
                                companiesList.map(company => (
                                    <MenuItem key={company.tid} value={company.tid}>{company.name}</MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button type="submit" variant='contained'>Add</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
