import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { editCompany } from '../../apis/CompanyAPI';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function EditCompany({ data, onCompanyEditted }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [credits, setCredits] = React.useState(0);
    const [tid, setTid] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            await editCompany({
            name: name,
            description: description,
            meeting_creds: Number(credits), // Cast credits as a number
            tid: tid,
            reg_code: "False"
        });
                handleClose();
                // Call the callback function to update the list of companies
                onCompanyEditted();
            }
            catch (error) {
                console.error('Error editting Company:', error);
            }
        };
    return (
        <React.Fragment>
            <EditNoteOutlinedIcon onClick={handleClickOpen} sx={{
                color: "black",
                background: "none",
                border: "none",
                float: "right",
                margin: "0px 0px 0px 10px",
            }} />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>Edit Company</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{paddingBottom: 3}}>
                        To edit a company, please fill in these fields.
                    </DialogContentText>
                    <FormControl fullWidth>
                    <InputLabel id="company-name-label">Company Name</InputLabel>
                    <Select
                        required
                        labelId='company-name-label'
                        id='company-name'
                        value={name}
                        label='Company Name'
                        fullWidth
                        onChange={(e) => {
                            const selectedName = e.target.value;
                            const selectedCompany = data.find(company => company.name === selectedName);
                            if (selectedCompany) {
                                setName(selectedName);
                                setTid(selectedCompany.tid);
                                setDescription(selectedCompany.description);
                                setCredits(selectedCompany.meeting_cred);

                            }
                        }}
                    >
                        {data.map(company => (
                            <MenuItem key={company.tid} value={company.name}>{company.name}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='description'
                        value={description}
                        margin='dense'
                        id='description'
                        label='Description'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='credits'
                        value={credits}
                        margin="dense"
                        id="credits"
                        label="Credits"
                        type="number"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setCredits(e.target.value)}
                    />
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button type="submit" variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
