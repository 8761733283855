import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
// import RoomBooking from './components/RoomBooking'
import { Divider } from '@mui/material';
import LocationsList from '../components/LocationList'

export default function LandingPage() {
    const [mode, setMode] = React.useState('light');
    const LPtheme = createTheme(getLPTheme(mode));

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            {/* TODO: Make background common */}
            <Box sx={{
                backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)', backgroundSize: '100% 200px',
                backgroundRepeat: 'no-repeat', display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: { xs: 14, sm: 20 }
            }}>
                <LocationsList />
                <Divider />
                <Footer />
            </Box>
        </ThemeProvider>
    );
}
