// src/pages/ResetPassword.js
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import axios from 'axios';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';

export default function ResetPassword() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [error, setError] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const { token } = useParams();  // Capture token from path parameter
  const navigate = useNavigate();

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('password').length === 0 || data.get('confirmPassword').length === 0) {
      setError("Please fill in all fields.");
      setShowAlert(true);
      return;
    }

    if (data.get('password') !== data.get('confirmPassword')) {
      setError("Passwords do not match.");
      setShowAlert(true);
      return;
    }

    if (!token) {
      setError("Invalid reset link.");
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
    axios.post(
      `${process.env.REACT_APP_API_URL}/api/reset-password`,
      {
        password: data.get('password'),
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then(function (response) {
        // Redirect to login with success message
        navigate('/sign-in', { 
          state: { message: "Password has been reset successfully. Please sign in with your new password." }
        });
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          setError("Invalid or expired reset link. Please request a new one.");
        } else {
          setError("Something went wrong. Please try again later.");
        }
        setShowAlert(true);
      });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
        backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockResetIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Set New Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <RouterLink 
                to="/sign-in"
                style={{ 
                  color: 'inherit',
                  textDecoration: 'none',
                  fontFamily: LPtheme.typography.body2.fontFamily,
                  fontSize: LPtheme.typography.body2.fontSize
                }}
              >
                Back to Sign In
              </RouterLink>
              <Alert
                variant="outlined"
                severity="error"
                sx={{ marginTop: 2, visibility: showAlert ? 'visible' : 'hidden' }}
              >
                {error}
              </Alert>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
} 
