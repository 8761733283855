import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppAppBar from '../components/AppAppBar';
import getLPTheme from './getLPTheme';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RoomBookings from '../components/Dashboard/RoomBookings';
import Footer from '../components/Footer';
import Companies from '../components/Dashboard/Companies';
import Users from '../components/Dashboard/Users';
import Rooms from '../components/Dashboard/Rooms';
import Locations from '../components/Dashboard/Locations';
import { Grid } from '@mui/material';

export default function Dashboard() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [value, setValue] = React.useState(0);

  // Load the selected tab value from localStorage on component mount
  React.useEffect(() => {
    const storedValue = localStorage.getItem('selectedTab');
    if (storedValue !== null) {
      setValue(parseInt(storedValue, 10));
    }
  }, []);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleChange = (event, newValue) => {
    // Store the selected tab value in localStorage
    localStorage.setItem('selectedTab', newValue);
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
          backgroundSize: '100% 200px',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
        }}
      >

        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            mb: 8,
            width: { xs: '100%', sm: 'auto', md: 'auto', xl: 'auto' },
          }}
        >
          <Tab label="Rooms" />
          <Tab label="Companies & Locations" />
          <Tab label="Users" />
        </Tabs>
        <Container maxWidth="lg" sx={{ mb: 4 }}>

          {/* Room Bookings */}
          {value === 0 && (
            <RoomBookings />
          )}

          {/* Companies */}
          {value === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Companies />
              </Grid>
              <Grid item xs={12}>
                <Locations />
              </Grid>
              <Grid item xs={12}>
                <Rooms />
              </Grid>
            </Grid>
          )}

          {/* Users */}
          {value === 2 && (
            <Users />
          )}
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
