import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
// import RoomBooking from './components/RoomBooking'
import { Divider, Typography } from '@mui/material';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';

export default function NotFound() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {/* TODO: Make background common */}
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)', backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat', display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <div fontSize='800px'>
            <SearchOffRoundedIcon sx={{fontSize: '200px', opacity: '0.1'}}/>
        </div>
        <Typography variant="h3" sx={{opacity:"0.1"}}>404 not found</Typography>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
