import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';
import axios from "axios";

function DashboardTable({ data, columns, columnMapping, refreshData, formatValues }) { 
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(columns[0]); //initialize with first column
  const [sortOrder, setSortOrder] = useState("asc");

  // api endpoint
  const editCompaniesApi =
    process.env.REACT_APP_API_URL + "/api/edit-company";

  // Function to handle icon click
  const handleIconClick = (data, regCode) => {
    // Make Axios POST request
    axios.post(editCompaniesApi, { tid: data.tid, reg_code: regCode, name: data.name, description: data.description, meeting_creds: data.meeting_cred })
      .then((response) => {
        // Handle success
        console.log("POST request successful:", response.data);
        // Call the refreshData function
        refreshData();
      })
      .catch((error) => {
        // Handle error
        console.error("Error making POST request:", error);
      });
  };

  // Filter data based on search query
  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Function to handle sorting
  const handleSort = (column) => {
    if (sortBy === column) {
      // If already sorting by this column, reverse the order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If sorting by a new column, set the new column and default to ascending order
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortBy === null) return 0;

    const aValue = a[columnMapping[sortBy]];
    const bValue = b[columnMapping[sortBy]];

    // Handle string comparison
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }

    // Handle numeric comparison
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    }

    // Fallback to default comparison
    return 0;
  });

  return (
    <div>
      {/* Search TextField */}
      <TextField
        label="Search..."
        variant="standard"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin="dense"
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>
                  <TableSortLabel
                    active={sortBy === column}
                    direction={sortBy === column ? sortOrder : "asc"}
                    onClick={() => handleSort(column)}
                  >
                    {column}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Render Skeleton when sortedData is empty */}
            {sortedData.length === 0 ? (
              <TableRow>
                {columns.map((_, colIndex) => (
                  <TableCell key={colIndex}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              sortedData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex}>
                      <div style={{
                        display: 'block',
                        maxWidth: '200px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        {/* Check if formatValues is provided and format the value accordingly */}
                        {formatValues && formatValues[column]
                          ? formatValues[column](row[columnMapping[column]])
                          : row[columnMapping[column]]}
                        {column === "Registration Code" && (
                          <Icon onClick={() => handleIconClick(row, "True")} sx={{ float: "right" }}>
                            <AutorenewOutlinedIcon style={{ float: "right" }} />
                          </Icon>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DashboardTable;
