// refresh.js
import axios from "axios";
import createRefresh from "react-auth-kit/createRefresh";

const refreshApi = process.env.REACT_APP_API_URL + "/api/refresh-token";

const refresh = createRefresh({
  interval: 9, // Refresh 1 minute before the 10-minute expiry
  refreshApiCallback: async ({ refreshToken }) => {
    try {
      // Add Authorization header with refresh token
      const response = await axios.post(
        refreshApi,
        { refreshToken },
        {
          headers: {
            'Authorization': `Bearer ${refreshToken}`
          }
        }
      );

      // Return the refresh result
      return {
        isSuccess: true,
        newAuthToken: response.data.access_token,
        newRefreshToken: response.data.refresh_token,
        // Set these to match your backend token expiry times
        newAuthTokenExpireIn: 10, // 10 minutes
        newRefreshTokenExpiresIn: 30 * 24 * 60, // 30 days
      };
    } catch (error) {
      console.error("Error refreshing token:", error);
      return {
        isSuccess: false,
      };
    }
  },
});

export { refresh };