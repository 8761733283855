import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "./Title";

export default function Deposits({ totalCredits, minDate, maxDate }) {
  // Calculate the credit value in Rupees
  const creditValueInRupees = totalCredits * 500;

  return (
    <React.Fragment>
      <Title>Credits Used</Title>
      <Typography
        component='p'
        variant='h4'
      >
        {totalCredits}
      </Typography>
      <Typography
        color='text.secondary'
        sx={{ flex: 1 }}
      >
        ₹{creditValueInRupees.toLocaleString()}
      </Typography>
      <div>
        <Typography
          color='text.secondary'
          sx={{ flex: 1 }}
        >
          From {minDate ? minDate.toLocaleDateString() : "N/A"} to{" "}
          {maxDate ? maxDate.toLocaleDateString() : "N/A"}
        </Typography>
      </div>
    </React.Fragment>
  );
}
