import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import SignIn from "./pages/SignIn";
import Pricing from "./pages/Pricing";
import Plans from "./pages/Plans";
import Rooms from "./pages/Rooms";
import SignUp from "./pages/SignUp";
import SecureComponent from "./components/SecureComponent";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import Locations from "./pages/Locations";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import NotFound from "./pages/404";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

export const App = () => {
  const RoleAccess = ({ roles = [] }) => {
    const user = useAuthUser();
    // console.log(user)
    // console.log(roles)
    return !roles.length || roles.includes(user?.typeId) ? (
      <Outlet />
    ) : (
      <Navigate
        to='/not-found'
        replace
      />
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={<LandingPage />}
        />
        <Route
          path='/sign-in'
          element={<SignIn />}
        />
        <Route
          path='/sign-up'
          element={<SignUp />}
        />
        <Route
          path='/forgot-password'
          element={<ForgotPassword />}
        />
        <Route
          path='/reset-password/:token'
          element={<ResetPassword />}
        />
        <Route
          path='/reset-password'
          element={<ResetPassword />}
        />
        {/* Add a new route that includes the token parameter */}
        <Route element={<SignIn />} />
        <Route
          path='/pricing'
          element={<Pricing />}
        />
        <Route
          path='/plans'
          element={<Plans />}
        />
        <Route
          path='/locations'
          element={<Locations />}
        />
        <Route
          path='/rooms'
          element={<Rooms />}
        />
        <Route
          path='/about'
          element={<About />}
        />
        <Route
          path='/contact-us'
          element={<Contact />}
        />
        <Route
          path='/profile'
          element={
            <RequireAuth fallbackPath={"/sign-in"}>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path={"/secure"}
          element={
            <RequireAuth fallbackPath={"/sign-in"}>
              <SecureComponent />
            </RequireAuth>
          }
        />
        <Route element={<RoleAccess roles={[3, 4]} />}>
          <Route
            path='/dashboard'
            element={
              <RequireAuth fallbackPath={"/sign-in"}>
                <Dashboard />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </Router>
  );
};
