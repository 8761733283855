import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import getLPTheme from './getLPTheme';
import Footer from '../components/Footer';
import AppAppBar from '../components/AppAppBar';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar';
import { useState } from 'react';


export default function SignUp() {
  const [mode, setMode] = React.useState('light');
  const [error, setError] = React.useState("");
  const LPtheme = createTheme(getLPTheme(mode));
  const [showAlert, setShowAlert] = React.useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const isPasswordMatch = () => {
    return password === confirmPassword;
  };

  const navigate = useNavigate();

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('email').length === 0) {
    }
    else if (data.get('password').length === 0) {
    }
    else {
      axios.post(process.env.REACT_APP_API_URL + '/api/sign-up', {
        //fname: data.get('')
        email: data.get('email'),
        password: data.get('password'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        reg_code: data.get('reg_code')
      })
        .then(function (response) {
          setError(response.data.error || "");
          if (response.status === 200) {
            navigate('/sign-in');
          }
          // console.log(response);
        })
        .catch(function (error) {
          // console.log(error, 'error');
          if (error.response.status === 400) {
            console.log(error);
            setError(error.response.data.error || "");
            setShowAlert(true)
          }
        });
    }
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {/* TODO: Make background common */}
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)', backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat', display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="reg_code"
                    label="Registration Code"
                    name="reg_code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <PasswordStrengthBar password={password} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange} // Call handleConfirmPasswordChange on change event
                    error={!isPasswordMatch()} // Add error state if passwords don't match
                    helperText={!isPasswordMatch() && "Passwords do not match"} // Display helper text if passwords don't match
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isPasswordMatch()}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link color="inherit" href="/sign-in" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              <Alert
                variant="outlined"
                severity="error"
                sx={{ marginTop: 2, visibility: showAlert ? 'visible' : 'hidden' }}
              >
                {error && error}
              </Alert>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
