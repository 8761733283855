import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const items = [
  {
    image: "/static/images/image-list/Manju_Bhatia.webp",
    name: "MANJU BHATIA",
    title: "CO-FOUNDER, CHIEF EXECUTIVE OFFICER",
    description:
      "Mrs. Manju Bhatia has more than 25 years of experience in the field of real estate, servicing, logistic management, education and much more. Mrs. Bhatia has helped setting up Prime Location Real Estate in UAE. After the success she moved to SIRVA Worldwide Relocation & Moving where she was a key part in structuring the logistic alliances. Mrs. Bhatia started her own entrepreneur journey with Mr Gandhi 5 years ago where she is spearheading all the operation and serving.",
  },
  {
    image: "/static/images/image-list/Jayesh_Gandhi.webp",
    name: "JAYESH GANDHI",
    title: "FOUNDER, MANAGING DIRECTOR",
    description:
      "Mr. Jayesh R. Gandhi was born 1967 in Mumbai in an esteemed business family that has had a history of business interests and social responsibility behind it. Mr. Gandhi is a passionate serial entrepreneur with more than 3 decades of experience in real estate, automobile, import-export, dealership and much more.",
  },
];

export default function About() {
  return (
    <Box
      id='about'
      sx={{ pb: 8 }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          variant='h6'
          textAlign={"justify"}
        >
          Millennial Pod is an innovative, fully serviced workspace where companies and individuals grow together. We offer co-working and office space solutions tailored to all types of businesses and professionals. Believing in the power of a feel-good workplace, we provide open spaces designed for you to unwind and recharge. Cozy areas with sofas and lounge seating invite you to take creative breaks. Our library offers a quiet retreat for reading, while our event space provides a variety of options for engaging both body and mind. We regularly organize events for our members and their guests, offering opportunities to connect, learn, and collaborate with like-minded coworkers.
        </Typography>
        {items.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            direction={{ xs: "column", sm: "row" }}
            alignItems={"center"}
            mt={8}
          >
            <Grid
              item
              xs={4}
              md={4}
            >
              <img
                src={item.image}
                alt='Millennial Pod Founders'
                style={{
                  width: "250px",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 8px 4px rgba(203, 224, 15, 0.2)",
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              md={8}
            >
              <Typography variant='h4'>{item.name}</Typography>
              <Typography variant='h5'>{item.title}</Typography>
              <Typography variant='h6' textAlign='justify'>{item.description}</Typography>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Box>
  );
}
