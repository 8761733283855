import axios from "axios";

const getRoomsApi = process.env.REACT_APP_API_URL + "/api/get-rooms-list";
const createRoomApi = process.env.REACT_APP_API_URL + "/api/create-room";

const fetchRooms = async () => {
    try {
        const response = await axios.get(getRoomsApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Rooms:", error);
        throw error;
    }
};

const addRoom = async (roomData) => {
    try {
        const response = await axios.post(createRoomApi, roomData);
        return response.data;
    } catch (error) {
        console.error("Error adding Room:", error);
        throw error;
    }
};

export { fetchRooms, addRoom };
