import axios from "axios";

const getLocationsApi = process.env.REACT_APP_API_URL + "/api/get-locations";
const createLocationApi = process.env.REACT_APP_API_URL + "/api/create-location";

const fetchLocations = async () => {
    try {
        const response = await axios.get(getLocationsApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Locations:", error);
        throw error;
    }
};

const addLocation = async (locationData) => {
    try {
        const response = await axios.post(createLocationApi, locationData);
        return response.data;
    } catch (error) {
        console.error("Error adding Location:", error);
        throw error;
    }
};

export { fetchLocations, addLocation };
