import { useState, MouseEvent, useEffect } from "react";
import {
  Box,
  // Button,
  // ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from "@mui/material";

import { Calendar, Event, dateFnsLocalizer } from "react-big-calendar";

import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";

import "react-big-calendar/lib/css/react-big-calendar.css";

import EventInfo from "./EventInfo";
import AddEventModal from "./AddEventModal";
import EventInfoModal from "./EventInfoModal";
import { AddTodoModal } from "./AddTodoModal";
import AddDatePickerEventModal from "./AddDatePickerEventModal";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axios from "axios";
// import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export interface ITodo {
  _id: string;
  title: string;
  color?: string;
}

export interface IEventInfo extends Event {
  _id: string;
  description: string;
  todoId?: string;
  userId: number; // User ID from User Object
  companyId: number; // CID from User Object
  email: string; // User ID from User Object
  companyName: string;
  roomId: number;
}

export interface EventFormData {
  description: string;
  todoId?: string;
  userId: number; // User ID from User Object
  companyId: number; // CID from User Object
  email: string; // User ID from User Object
  companyName: string; // CID from User Object
  roomId: number;
}

export interface DatePickerEventFormData {
  description: string;
  todoId?: string;
  allDay: boolean;
  start?: Date;
  end?: Date;
}

// Defining the type for the roomName prop
interface EventCalendarProps {
  roomName: string;
  roomid: string;
}

export const generateId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

function hashCode(str: string) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function generateColor(i: number): string {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();
  let brightness =
    (parseInt(c, 16) >> 16) +
    ((parseInt(c, 16) >> 8) & 255) +
    (parseInt(c, 16) & 255);

  // If the brightness is lower than 382 (arbitrary threshold), adjust the color to be lighter
  if (brightness < 382) {
    let adjust = 382 - brightness;
    c = (parseInt(c, 16) + adjust * 0x010101).toString(16).toUpperCase();
    // If the adjusted color exceeds ffffff, set it to ffffff
    c = Math.min(parseInt(c, 16), 0xffffff).toString(16).toUpperCase();
  }

  return "#" + "00000".substring(0, 6 - c.length) + c;
}

const initialEventFormState: EventFormData = {
  description: "",
  todoId: undefined,
  userId: 0,
  companyId: 0,
  email: "",
  companyName: "",
  roomId: 0,
};

const initialDatePickerEventFormData: DatePickerEventFormData = {
  description: "",
  todoId: undefined,
  allDay: false,
  start: undefined,
  end: undefined,
};

const EventCalendar: React.FC<EventCalendarProps> = ({ roomName, roomid }) => {
  //extracts the roomName prop from the props object and uses it within the component.
  // API endpoints
  const getEventsApi =
    process.env.REACT_APP_API_URL + `/api/get-events-list?roomId=${roomid}`;
  const createEventApi = process.env.REACT_APP_API_URL + "/api/create-event";
  const deleteEventApi = process.env.REACT_APP_API_URL + "/api/delete-event";

  const authUser = useAuthUser<any>();
  // const isAuthenticated = useIsAuthenticated();
  const [openSlot, setOpenSlot] = useState(false);
  const [openDatepickerModal, setOpenDatepickerModal] = useState(false);
  const [openTodoModal, setOpenTodoModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | IEventInfo | null>(
    null
  );

  const [eventInfoModal, setEventInfoModal] = useState(false);

  const [events, setEvents] = useState<IEventInfo[]>([]);
  const [todos, setTodos] = useState<ITodo[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(getEventsApi, {
          // Add any necessary headers or parameters to the request
          // For example: headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Check if the response contains the expected property
        if (response && response.data) {
          // Assuming the response contains an array of events
          const fetchedEvents: IEventInfo[] = response.data.map(
            (event: IEventInfo) => {
              const start = event.start ? new Date(event.start) : new Date(); // Use current date if start is undefined
              const end = event.end ? new Date(event.end) : new Date(); // Use current date if end is undefined

              return {
                ...event,
                start,
                end,
              };
            }
          );

          // Update the events state with the fetched data
          setEvents(fetchedEvents);
        } else {
          console.error("Invalid response format:", response);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function when the component mounts
    fetchEvents();
  }, [getEventsApi]);

  const [eventFormData, setEventFormData] = useState<EventFormData>(
    initialEventFormState
  );

  const [datePickerEventFormData, setDatePickerEventFormData] =
    useState<DatePickerEventFormData>(initialDatePickerEventFormData);

  const handleSelectSlot = (event: Event) => {
    setOpenSlot(true);
    setCurrentEvent(event);
  };

  const handleSelectEvent = (event: IEventInfo) => {
    setCurrentEvent(event);
    setEventInfoModal(true);
  };

  const handleClose = () => {
    setEventFormData(initialEventFormState);
    setOpenSlot(false);
  };

  const handleDatePickerClose = () => {
    setDatePickerEventFormData(initialDatePickerEventFormData);
    setOpenDatepickerModal(false);
  };

  const isTimeSlotAvailable = (
    newEvent: Event | IEventInfo,
    events: IEventInfo[]
  ) => {
    // Check if newEvent is null or undefined
    if (!newEvent) {
      return false; // Return false if newEvent is null or undefined
    }

    // Check if any event in the events array is null or undefined
    if (events.some((event) => !event)) {
      return false; // Return false if any event is null or undefined
    }

    if (newEvent.start && newEvent.start < new Date()) {
      return true;
    }

    // Check if the time slot overlaps with any existing event
    return events.some(
      (event) => newEvent.start! < event.end! && newEvent.end! > event.start!
    );
  };

  // Event is added here - work on adding logic
  const onAddEvent = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    const companyIdToUse =
      eventFormData.companyId !== 0 && eventFormData.companyId !== undefined
        ? eventFormData.companyId
        : authUser.companyId;
  
    console.log(companyIdToUse);
  
    const data: IEventInfo = {
      ...eventFormData,
      _id: generateId(),
      start: currentEvent?.start,
      end: currentEvent?.end,
      userId: authUser.uid,
      email: authUser.email,
      companyId: companyIdToUse,  // Ensure companyId is correctly assigned
      companyName: "",
      roomId: Number(roomid),
    };
  
    console.log(data);
  
    axios
      .post(createEventApi, {
        _id: data._id,
        start: data.start,
        end: data.end,
        userId: data.userId,
        companyId: data.companyId,
        roomId: data.roomId,
        description: data.description,
        todoId: data.todoId,
      })
      .then(function (response) {
        const newEvents = [...events, data];
  
        setEvents(newEvents);
        handleClose();
      })
      .catch(function (error) {
        alert(error.response.data.error);
      });
  };

  const onAddEventFromDatePicker = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const addHours = (date: Date | undefined, hours: number) => {
      return date ? date.setHours(date.getHours() + hours) : undefined;
    };

    const setMinToZero = (date: any) => {
      date.setSeconds(0);

      return date;
    };

    const data: IEventInfo = {
      ...datePickerEventFormData,
      _id: generateId(),
      start: setMinToZero(datePickerEventFormData.start),
      end: datePickerEventFormData.allDay
        ? addHours(datePickerEventFormData.start, 12)
        : setMinToZero(datePickerEventFormData.end),
      userId: authUser.uid, // User ID from User Object
      companyId: 0, // CID from User Object
      email: authUser.email, // User ID from User Object
      companyName: "", // CID from User Object
      roomId: 0,
    };

    const newEvents = [...events, data];

    setEvents(newEvents);
    setDatePickerEventFormData(initialDatePickerEventFormData);
  };

  const onDeleteEvent = () => {
    axios
      .post(deleteEventApi, {
        //fname: data.get('')
        _id: (currentEvent as IEventInfo)._id,
        companyId: (currentEvent as IEventInfo).companyId,
      })
      .then(function (response) {
        setEvents(() =>
          [...events].filter((e) => e._id !== (currentEvent as IEventInfo)._id!)
        );
        setEventInfoModal(false);
      })
      .catch(function (error) {
        alert(error.response.data.error);
      });
  };

  return (
    <Box
      mb={2}
      component='main'
      sx={{
        flexGrow: 1,
        pb: 8,
      }}
    >
      <Container maxWidth={false}>
        <Card
          sx={{
            background: "#00000000",
            border: "none",
            boxShadow: "0px 0px 16px 8px rgba(203, 224, 15, 0.2)",
          }}
        >
          <CardHeader
            title={roomName}
            subheader='Book a meeting!'
          />
          <Divider />
          <CardContent>
            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonGroup>
                <Button
                  onClick={() => setOpenDatepickerModal(true)}
                  size="medium"
                  variant="contained"
                >
                  New Meeting
                </Button>
              </ButtonGroup>
            </Box> */}
            {/* <Divider style={{ margin: 10 }} /> */}
            <AddEventModal
              open={openSlot}
              handleClose={handleClose}
              eventFormData={eventFormData || initialEventFormState}
              setEventFormData={setEventFormData}
              onAddEvent={onAddEvent}
              todos={todos}
              timeSlotAvailable={!isTimeSlotAvailable(currentEvent!, events)}
              start={currentEvent?.start?.toLocaleTimeString()!}
              end={currentEvent?.end?.toLocaleTimeString()!}
            />
            <AddDatePickerEventModal
              open={openDatepickerModal}
              handleClose={handleDatePickerClose}
              datePickerEventFormData={
                datePickerEventFormData || initialDatePickerEventFormData
              }
              setDatePickerEventFormData={setDatePickerEventFormData}
              onAddEvent={onAddEventFromDatePicker}
              todos={todos}
              timeSlotAvailable={!isTimeSlotAvailable(currentEvent!, events)}
            />
            <EventInfoModal
              open={eventInfoModal}
              handleClose={() => setEventInfoModal(false)}
              onDeleteEvent={onDeleteEvent}
              currentEvent={currentEvent as IEventInfo}
            />
            <AddTodoModal
              open={openTodoModal}
              handleClose={() => setOpenTodoModal(false)}
              todos={todos}
              setTodos={setTodos}
            />
            <Calendar
              localizer={localizer}
              events={events}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              dayLayoutAlgorithm={"no-overlap"}
              startAccessor='start'
              components={{ event: EventInfo }}
              step={15} // Set time incriments
              min={new Date(0, 0, 0, 9, 0, 0)} //min date and time
              max={new Date(0, 0, 0, 21, 0, 0)} //max date and time
              endAccessor='end'
              defaultView='day'
              views={{
                month: false,
                week: true,
                day: true,
                agenda: true,
              }}
              eventPropGetter={(event) => {
                const hasTodo = todos.find((todo) => todo._id === event.todoId);
                return {
                  style: {
                    // Modified to generate a color based on event _id
                    backgroundColor: hasTodo
                      ? hasTodo.color
                      : generateColor(hashCode(event._id)),
                    borderColor: hasTodo
                      ? hasTodo.color
                      : generateColor(hashCode(event._id)),
                    color: "black",
                  },
                };
              }}
              style={{
                height: 900,
                width: "80vw",
              }}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default EventCalendar;
