import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";

import Title from "./Title";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount: amount ?? null };
}

export default function Chart({ creditsByDate }) {
  const theme = useTheme();

  // Convert creditsByDate to the format expected by LineChart
  const data = creditsByDate.map(({ date, credits }) => {
    return createData(date, credits);
  });

  // Get the last 30 days of data
  const last30DaysData = [];
  const endDate = new Date();
  endDate.setDate(endDate.getDate());
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30); // Set start date to 30 days ago
  const dateRange = getDateRange(startDate, endDate);

  for (const date of dateRange) {
    const formattedDate = date.toLocaleDateString();
    const dataItem = data.find(item => item.time === formattedDate);
    if (dataItem) {
      last30DaysData.push(dataItem);
    } else {
      last30DaysData.push(createData(formattedDate, 0));
    }
  }

  function getDateRange(startDate, endDate) {
    const dateRange = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
  }

  return (
    <React.Fragment>
      <Title>Credits used in the last 30 days</Title>
      <div style={{ width: "100%", flexGrow: 1, overflow: "hidden"}}>
        <LineChart
          dataset={last30DaysData}
          margin={{
            top: 16,
            right: 35,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: "point",
              dataKey: "time",
              tickNumber: 14,
              tickLabelStyle: theme.typography.body2,
              angle: -45, // Rotate labels at an angle
              dy: 10, // Adjust vertical positioning if needed
            },
          ]}
          yAxis={[
            {
              label: "Total Credits",
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              tickNumber: 5,
            },
          ]}
          series={[
            {
              dataKey: "amount",
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: {
              stroke: theme.palette.text.secondary,
            },
            [`.${axisClasses.root} text`]: {
              fill: theme.palette.text.secondary,
            },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translateX(-25px)",
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
