import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { editUser } from '../../apis/UserAPI';

export default function EditUser({ data, onUserEditted, types, companies }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [contact, setContact] = React.useState('');
    const [uid, setUid] = React.useState(0);
    const [tid, setTid] = React.useState('');
    const [type, setType] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            await editUser({
                name: name,
                change_pass: "False",
                contact: contact,
                uid: uid,
                type_id: type,
                tid: tid,
            });
            handleClose();
            // Call the callback function to update the list of companies
            onUserEditted();
        }
        catch (error) {
            console.error('Error editting user:', error);
        }
    };

    return (
        <React.Fragment>
            <EditNoteOutlinedIcon onClick={handleClickOpen} sx={{
                color: "black",
                background: "none",
                border: "none",
                float: "right",
                margin: "0px 0px 0px 10px",
            }} />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ paddingBottom: 3 }}>
                        To edit a user, please fill in these fields.
                    </DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel id="user-email-label">User Email</InputLabel>
                        <Select
                            required
                            labelId='user-email-label'
                            id='user-email'
                            value={contact}
                            label='User Email'
                            fullWidth
                            onChange={(e) => {
                                const selectedContact = e.target.value;
                                const selectedUser = data.find(user => user.contact === selectedContact);
                                if (selectedUser) {
                                    setContact(selectedContact);
                                    setName(selectedUser.name);
                                    setUid(selectedUser.uid);
                                    setTid(selectedUser.tid);
                                    const selectedType = types.find(type => type.user_type === selectedUser.user_type);
                                    setType(selectedType.type_id);
                                }
                            }
                            }
                        >
                            {data.map(user => (
                                <MenuItem key={user.uid} value={user.contact}>{user.contact}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                            required
                            name='name'
                            value={name}
                            margin='dense'
                            id='name'
                            label='Name'
                            type='name'
                            fullWidth
                            variant='outlined'
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="company-label">User Company</InputLabel>
                        <Select
                            required
                            labelId='company-label'
                            id='tid'
                            value={tid}
                            label='User Company'
                            fullWidth
                            onChange={(e) => setTid(e.target.value)}
                        >
                            {companies.map(company => (
                                <MenuItem key={company.tid} value={company.tid}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="type-id-label">User Type</InputLabel>
                        <Select
                            required
                            labelId='type-id-label'
                            id='type_id'
                            value={type}
                            label='User Type'
                            fullWidth
                            onChange={(e) => setType(e.target.value)}
                        >
                            {types.map(types => (
                                <MenuItem key={types.type_id} value={types.type_id}>{types.user_type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button type="submit" variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
