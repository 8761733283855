import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { addRoom } from '../../apis/RoomAPI';

export default function AddRoom({ onRoomAdded, locationsList }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [lid, setLid] = React.useState('');
    const [capacity, setCapacity] = React.useState('');
    const [credits, setCredits] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            await addRoom({
                name: name,
                lid: lid,
                cap: capacity,
                credits_hourly: credits
            });
            handleClose();
            onRoomAdded();
        } catch (error) {
            console.error('Error adding Room:', error);
        }
    };

    return (
        <React.Fragment>
            <AddRoundedIcon onClick={handleClickOpen} sx={{
                color: "black",
                background: "none",
                border: "none",
                float: "right",
                margin: "0px 0px 0px 10px",
            }} />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>New Room</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a room, please fill in these fields.
                    </DialogContentText>
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='name'
                        value={name}
                        margin='dense'
                        id='name'
                        label='Room Name'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            required
                            value={lid}
                            onChange={(e) => setLid(e.target.value)}
                            fullWidth
                            id="location"
                            labelId="location-label"
                            label="Location"
                        >
                            {locationsList.map(location => (
                                <MenuItem key={location.lid} value={location.lid}>{location.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='capacity'
                        value={capacity}
                        margin="dense"
                        id="capacity"
                        label="Capacity"
                        type="number"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setCapacity(e.target.value)}
                    />
                    <TextField
                        required
                        sx={{ mt: 3 }}
                        name='credits'
                        value={credits}
                        margin="dense"
                        id="credits"
                        label="Credits"
                        type="number"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setCredits(e.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button type="submit" variant='contained'>Add</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
