import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const items = [
  {
    image: "/static/images/image-list/moscow.webp",
    title: "Private Office",
    description:
      "Enclosed, lockable offices accommodating big & small teams. Fully furnished and serviced.",
    additional: [
      "Corporate set up",
      "Managed Office Set up",
      "Exclusive functionalities",
      "Flexible scaling",
    ],
  },
  {
    image: "/static/images/image-list/zurich.webp",
    title: "Meeting Rooms",
    description:
      "In The Commercial Hub Of Mumbai. Fully Services And Equipped With All Facilities For Any Type Of Meetings.",
    additional: [
      "Corporate set up",
      "Managed Office Set up",
      "Exclusive functionalities",
      "Flexible scaling",
    ],
  },
  {
    image: "/static/images/image-list/dedicated-desk.webp",
    title: "Dedicated Desk (Open/Cabin)",
    description:
      "A desk of your own in our fully serviced workspace. Work from the same desk everyday.",
    additional: [
      "Corporate set up",
      "Managed Office Set up",
      "Exclusive functionalities",
      "Flexible scaling",
    ],
  },
  {
    image: "/static/images/image-list/hot-desk.webp",
    title: "Hot Desk",
    description:
      "Guaranteed workspace in our common area. Just bring your laptop, pick an open seat, and get to work.",
    additional: [
      "Corporate set up",
      "Managed Office Set up",
      "Exclusive functionalities",
      "Flexible scaling",
    ],
  },
  {
    image: "/static/images/image-list/day-pass.webp",
    title: "Day Pass",
    description: "Access to any available desk in common area for 8 hours.",
    additional: ["Flexible Seating", "Complete access to our space"],
  },
  {
    image: "/static/images/image-list/reception.webp",
    title: "Virtual Membership",
    description:
      "Establish your presence in the market by giving your business a corporate correspondence address.",
    additional: [
      "Free Lounge Access",
      "Correspondence Address",
      "Byte Cafe – Cafeteria",
      "Community Events & Activities",
    ],
  },
];

export default function Highlights() {
  return (
    <Box
      id='highlights'
      sx={{ pb: 8 }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component='h2'
          variant='h2'
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          Plans
        </Typography>
        <Grid
          container
          spacing={2.5}
        >
          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
            >
              <Stack
                direction='column'
                color='inherit'
                sx={{
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  pb: "5%",
                }}
              >
                <Box sx={{ width: "100%", position: "relative" }}>
                  <img
                    src={item.image}
                    alt='Millennial Pod Plans'
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 8px 4px rgba(203, 224, 15, 0.2)",
                    }}
                  />
                </Box>
                <Box sx={{ p: 2, flexGrow: 1 }}>
                  <Typography variant='h4'>{item.title}</Typography>
                  <Typography variant='h6'>{item.description}</Typography>
                  <ul>
                    {/* Map over each item in item.additional array */}
                    {item.additional.map((point, idx) => (
                      // Render each point as a list item with a unique key (idx)
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                </Box>
                <Button
                  variant='contained'
                  href='/contact-us'
                >
                  Contact Us
                </Button>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
