import * as React from 'react';
//import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Plans from '../components/Plans';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)', backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat', display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Hero />
        <Plans />
        <Divider />
        <Highlights />
        <LogoCollection />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
