import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PlaceRoundedIcon from "@mui/icons-material/PlaceRounded";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";

const items = [
  {
    icon: <PlaceRoundedIcon />,
    title: "Connectivity",
    description: "Excellent connectivity across the city & beyond.",
  },
  {
    icon: <WifiRoundedIcon />,
    title: "Tech Infrastructure",
    description:
      "IoT, NFC based access, CCTV coverage, VC facility, Enterprise speed internet.",
  },
  {
    icon: <Diversity3RoundedIcon />,
    title: "Networking",
    description:
      "Brainstorm , get valuable real-time feedback or a friendly advice on your big idea.",
  },
  {
    icon: <BusinessRoundedIcon />,
    title: "Bundled Services",
    description:
      "Ergonomically designed operational area provided with dedicated top-notch facilities.",
  },
];

export default function Highlights() {
  return (
    <Box
      id='highlights'
      sx={{ pb: 8 }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component='h2'
            variant='h4'
          >
            Highlights
          </Typography>
        </Box>
        <Grid
          container
          spacing={2.5}
        >
          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
            >
              <Stack
                direction='column'
                color='inherit'
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  boxShadow: "0px 0px 8px 4px rgba(203, 224, 15, 0.2)",
                }}
              >
                <Box sx={{ opacity: "70%" }}>{item.icon}</Box>
                <div>
                  <Typography
                    fontWeight='medium'
                    gutterBottom
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: "grey.600" }}
                  >
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
