import React from "react";
import { useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import axios from "axios";

const SecureComponent = () => {
    // api endpoint
    const signOutApi =
    process.env.REACT_APP_API_URL + "/api/signout";
  const signOut = useSignOut();
  const authUser = useAuthUser();
  const navigate = useNavigate();

  const signOutAction = () => {
    signOut();
    try {
      axios.get(signOutApi);
    } catch (error) {
      console.error("Error Logging out:", error);
      // Handle error, show error message, etc.
    }
    navigate("/sign-in");
  };

  // console.log(authUser);

  return (
    <div>
      <p>{`Hello ${authUser.name}, your U-ID is: ${authUser.uid}`}</p>
      <button onClick={signOutAction}>Sign Out!</button>
    </div>
  );
};

export default SecureComponent;
