import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
// Commented since disabled dark mode toggle
// import Button from '@mui/material/Button';
// import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
// import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

function ToggleColorMode({ mode, toggleColorMode }) {
  return (
    <Box sx={{ maxWidth: "32px" }}>
      {/* Button to toggle light and dark mode. Hidden for now */}

      {/* <Button
        variant="text"
        onClick={toggleColorMode}
        size="small"
        aria-label="button to toggle theme"
        sx={{ minWidth: '32px', height: '32px', p: '4px' }}
      >
        {mode === 'dark' ? (
          <WbSunnyRoundedIcon fontSize="small" />
        ) : (
          <ModeNightRoundedIcon fontSize="small" />
        )}
      </Button> */}
    </Box>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
