import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const BasicSelect = ({ label, itemList, selectedValue, onValueChange }) => {
  const [selectedItem, setSelectedItem] = useState(selectedValue);

  useEffect(() => {
    // Reset the value when the selected value changes
    setSelectedItem(selectedValue); // Corrected from setSelectedValue to setSelectedItem
  }, [selectedValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedItem(newValue);
    onValueChange(newValue);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`demo-simple-select-label-${label}`}>
          {label}
        </InputLabel>
        <Select
          labelId={`demo-simple-select-label-${label}`}
          id={`demo-simple-select-${label}`}
          value={selectedItem}
          label={label}
          onChange={handleChange}
        >
          {itemList.map((item) => {
            // Check if the option should be visible based on the selected value
            if (item.showWhen === undefined || item.showWhen) {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
