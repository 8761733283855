import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "./Title";
import CsvDownloadButton from "react-json-to-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DashboardTable from "./DashboardTable";
import EditUser from "./EditUser";
import { fetchUsers, fetchTypes } from "../../apis/UserAPI";
import { fetchCompanies } from "../../apis/CompanyAPI";

function Users() {
  const [users, setUsers] = useState([]);
  const [types, setTypes] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUsers(await fetchUsers());
        setTypes(await fetchTypes());
        setCompanies(await fetchCompanies());
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error, show error message, etc.
      }
    };
    fetchData();
  }, []);  

  const columns = [
    "User ID",
    "Type",
    "Name",
    "Email",
    "Company"
  ];

  const columnMapping = {
    "User ID": "uid",
    "Type": "user_type",
    "Name": "name",
    "Email": "contact",
    "Company": "company_name",
  };

  const handleUserAdded = async () => {
    // After a company is added, refetch the list of companies
    try {
      const updatedUsers = await fetchUsers();
      const updatedTypes = await fetchTypes();
      setUsers(updatedUsers);
      setTypes(updatedTypes);
    } catch (error) {
      console.error("Error fetching data after addition:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <React.Fragment>
            <Title>
              Users
              <CsvDownloadButton
                data={users}
                filename={"User List.csv"}
                style={{
                  background: "none",
                  border: "none",
                  padding: "0px 0px 0px 10px",
                  float: "right",
                }}
              >
                <FileDownloadOutlinedIcon />
              </CsvDownloadButton>
              <EditUser data={users} onUserEditted={handleUserAdded} types={types} companies={companies}/>
            </Title>
            <DashboardTable data={users} columns={columns} columnMapping={columnMapping} refreshData={fetchUsers}/>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Users;
