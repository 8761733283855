import axios from "axios";

const getCompaniesApi = process.env.REACT_APP_API_URL + "/api/get-companies-list";
const createCompanyApi = process.env.REACT_APP_API_URL + "/api/create-company";
const editCompanyApi = process.env.REACT_APP_API_URL + "/api/edit-company";

const fetchCompanies = async () => {
    try {
        const response = await axios.get(getCompaniesApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Companies:", error);
        throw error;
    }
};

const addCompany = async (companiesData) => {
    try {
        const response = await axios.post(createCompanyApi, companiesData);
        return response.data;
    } catch (error) {
        console.error("Error adding Company:", error);
        throw error;
    }
};

const editCompany = async (companyData) => { // Add this function
    try {
        const response = await axios.post(editCompanyApi, companyData);
        return response.data;
    } catch (error) {
        console.error("Error editing Company:", error);
        throw error;
    }
};

export { fetchCompanies, addCompany, editCompany };
