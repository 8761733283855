import React, { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "./Title";
import CsvDownloadButton from "react-json-to-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DashboardTable from "./DashboardTable";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import { fetchCompanies } from "../../apis/CompanyAPI";

function Companies() {
  const [companies, setCompanies] = useState([]);
  // Function to fetch companies
  const fetchCompaniesData = useCallback(async () => {
    try {
      const companiesData = await fetchCompanies();
      setCompanies(companiesData.map(company => ({
        ...company,
        create_date: new Date(company.create_date).toLocaleDateString()
      })));
    } catch (error) {
      console.error("Error fetching Companies:", error);
      // Handle error, show error message, etc.
    }
  }, []);

  useEffect(() => {
    fetchCompaniesData();
    }, [fetchCompaniesData]);

  const columns = [
    "Company ID",
    "Company Name",
    "Description",
    "Meeting Credits",
    "Create Date",
    "Registration Code"
  ];

  const columnMapping = {
    "Company ID": "tid",
    "Company Name": "name",
    "Description": "description",
    "Meeting Credits": "meeting_cred",
    "Create Date": "create_date",
    "Registration Code": "reg_code"
  };

  const handleCompanyAdded = async () => {
    // After a company is added, refetch the list of companies
    try {
        await fetchCompaniesData();
    } catch (error) {
      console.error("Error fetching Companies after addition:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <React.Fragment>
            <Title>
              Companies
              <CsvDownloadButton
                data={companies}
                filename={"Company List.csv"}
                style={{
                  background: "none",
                  border: "none",
                  margin: "0px 0px 0px 10px",
                  float: "right",
                }}
              >
                <FileDownloadOutlinedIcon />
              </CsvDownloadButton>
              <AddCompany onCompanyAdded={handleCompanyAdded} />
              <EditCompany data={companies} onCompanyEditted={handleCompanyAdded}/>
            </Title>
            <DashboardTable data={companies} columns={columns} columnMapping={columnMapping} refreshData={fetchCompaniesData}/>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Companies;
