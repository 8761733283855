// src/pages/ForgotPassword.js
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert } from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import axios from 'axios';

export default function ForgotPassword() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    if (data.get('email').length === 0) {
      setError("Please enter your email address.");
      setSuccess("");
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
    axios.post(process.env.REACT_APP_API_URL + '/api/forgot-password', {
      email: data.get('email'),
    })
      .then(function (response) {
        setError("");
        setSuccess(response.data.message);
        setShowAlert(true);
      })
      .catch(function (error) {
        setSuccess("");
        if (error.response && error.response.status === 404) {
          setError("No account found with this email address.");
        } else {
          setError("Something went wrong. Please try again later.");
        }
        setShowAlert(true);
      });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{
        backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
        backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 }
      }}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockResetIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, mb: 3, textAlign: 'center' }}>
              Enter your email address and we'll send you instructions to reset your password.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Instructions
              </Button>
              <Link color="inherit" href="/sign-in" variant="body2">
                Back to Sign In
              </Link>
              {error && (
                <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ marginTop: 2, visibility: showAlert ? 'visible' : 'hidden' }}
                >
                  {error}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="outlined"
                  severity="success"
                  sx={{ marginTop: 2, visibility: showAlert ? 'visible' : 'hidden' }}
                >
                  {success}
                </Alert>
              )}
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}