import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  ChangeEvent,
} from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Autocomplete,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePickerEventFormData, ITodo } from "./EventCalendar";

interface IProps {
  open: boolean;
  handleClose: Dispatch<SetStateAction<void>>;
  datePickerEventFormData: DatePickerEventFormData;
  setDatePickerEventFormData: Dispatch<SetStateAction<DatePickerEventFormData>>;
  onAddEvent: (e: MouseEvent<HTMLButtonElement>) => void;
  todos: ITodo[];
  timeSlotAvailable: boolean; // indicating if time slot is available
}

const AddDatePickerEventModal = ({
  open,
  handleClose,
  datePickerEventFormData,
  setDatePickerEventFormData,
  onAddEvent,
  todos,
  timeSlotAvailable,
}: IProps) => {
  const { description, start, end, allDay } = datePickerEventFormData;

  const onClose = () => {
    handleClose();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      allDay: event.target.checked,
    }));
  };

  const handleTodoChange = (e: React.SyntheticEvent, value: ITodo | null) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      todoId: value?._id,
    }));
  };

  const isDisabled = () => {
    return !description || !start || !end;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>New Meeting</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To book (room name), please fill in the information below.
        </DialogContentText>
        <Box component='form'>
          <TextField
            sx={{ mt: 3 }}
            name='description'
            value={description}
            margin='dense'
            id='description'
            label='Description'
            type='text'
            fullWidth
            variant='outlined'
            onChange={onChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mt={3}>
              <DateTimePicker
                label='Start date'
                value={start}
                ampm={true}
                minutesStep={15}
                onChange={(newValue) =>
                  setDatePickerEventFormData((prevState) => ({
                    ...prevState,
                    start: new Date(newValue!),
                  }))
                }
                slots={{
                  textField: (params) => <TextField {...params} />,
                }}
              />
            </Box>

            {/* Hidden for now */}
            <Box sx={{ display: "none" }}>
              <Typography
                variant='caption'
                color='text'
                component={"span"}
              >
                All day?
              </Typography>
              <Checkbox
                onChange={handleCheckboxChange}
                value={allDay}
              />
            </Box>

            <DateTimePicker
              sx={{ mt: 3 }}
              label='End date'
              disabled={allDay}
              minDate={start}
              minutesStep={15}
              ampm={true}
              value={allDay ? null : end}
              onChange={(newValue) =>
                setDatePickerEventFormData((prevState) => ({
                  ...prevState,
                  end: new Date(newValue!),
                }))
              }
              slots={{
                textField: (params) => <TextField {...params} />,
              }}
            />
          </LocalizationProvider>
          {/* Hidden for now */}
          <Autocomplete
            onChange={handleTodoChange}
            disablePortal
            id='combo-box-demo'
            options={todos}
            sx={{ marginTop: 4, display: "none" }}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Todo'
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='outlined'
        >
          Cancel
        </Button>
        <Button
          disabled={isDisabled() || !timeSlotAvailable}
          onClick={onAddEvent}
          variant='contained'
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDatePickerEventModal;
