import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getLPTheme from './getLPTheme';
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

export default function Contact() {
  const [mode, setMode] = React.useState('light');
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(null);
  const LPtheme = createTheme(getLPTheme(mode));
  const authUser = useAuthUser();

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Reset state
    setLoading(true);
    setSubmitted(false);
    setError(null);

    axios.post(process.env.REACT_APP_API_URL + '/api/send-message', {
      message: data.get('title'),
      recipient: "hardik@themillennialpod.com",
      reply_to: data.get('email'),
      body: data.get('description'),
      template: "None"
    })
      .then(function (response) {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        setLoading(false);
        setError("An error occurred while sending the message. Please try again.");
      });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        sx={{
          backgroundImage: 'linear-gradient(180deg, rgba(203, 224, 15, 0.2), #fff)',
          backgroundSize: '100% 200px',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 }
        }}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <MailOutlineIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Contact Us
            </Typography>
            <Typography component="h1" variant="h6" sx={{margin: '10px 0 0 0'}} textAlign={'center'}>
              Call us at <Link href="tel:+91 877-9657061">+91 877-9657061</Link> or send a message.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                defaultValue={authUser?.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="title"
                label="Message Title"
                id="title"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="description"
                label="Message Description"
                id="description"
                multiline
                rows={4}
              />

              {/* Loading Spinner */}
              {loading && <CircularProgress size={24} sx={{ mt: 2, mb: 2 }} />}

              {/* Success Message */}
              {submitted && <Alert severity="success" sx={{ mt: 2, mb: 2 }}>Message sent successfully!</Alert>}

              {/* Error Message */}
              {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading} // Disable button when loading
              >
                {loading ? "Sending..." : "Send Message"}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/" variant="body2">
                    Back to Home
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
